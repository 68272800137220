import { extendedExcelApi } from './endpoints/excelEndpoints';
import { keyThemesEndpoints } from './endpoints/keyThemesEndpoints';
import { modulesEndpoints } from './endpoints/modulesEndpoints';
import { peerGroupsExtendedApi } from './endpoints/peerGroupsEndpoints';
import { rebexApi } from './endpoints/baseRebexEndpoints';
import { reportsExtendedApi } from './endpoints/reportsEndpoints';
import { submodulesExtendedApi } from './endpoints/submodulesEndpoints';
import { usersExtendedApi } from './endpoints/usersEndpoints';
import { workspaceDocumentsExtendedApi } from './endpoints/workspaceDocumentsEndpoints';
import { workspacesExtendedApi } from './endpoints/workspacesEndpoints';

export const {
  useGetModulesByWorkspaceQuery,
  useLazyGetModulesByWorkspaceQuery,
  useUploadFlatFileMutation,
  useRetrieveTableauTrustedTicketQuery,
} = rebexApi;

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useLoginMutation,
  useGetDefaultWorkspaceByUserQuery,
  useAddUsersMutation,
  useAddAdminMutation,
  useAddDataLeadMutation,
  useAddDataCollectorMutation,
  useValidateInvitationLinkMutation,
  useExchangeOktaTokenMutation,
  useRequestResetPasswordMutation,
  useVerifyChangePasswordTokenMutation,
  useChangePasswordMutation,
  useChangeUserDetailsMutation,
  useGetAllUsersQuery,
  useRevokeUsersAccessMutation,
  useUpdateUserMutation,
  useInviteAdminMutation,
} = usersExtendedApi;

export const {
  useGetPeerGroupsQuery,
  useGetPeerGroupQuery,
  useAddPeerGroupMutation,
  useUpdatePeerGroupMutation,
  useDeletePeerGroupMutation,
  useGetOutlierAnalysisQuery,
  useUploadTriangulationFileMutation,
  useRecalculateKPIsMutation,
  useSaveOutlierAnalysisMutation,
} = peerGroupsExtendedApi;

export const { useGetKPIsQuery, useGetDatapointsQuery } = reportsExtendedApi;

export const {
  useGetAllWorkspacesQuery,
  useGetWorkspacesWithKpisQuery,
  useGetUserWorkspacesQuery,
  useLazyGetWorkspaceByIdQuery,
  useGetWorkspaceByIdQuery,
  useAddWorkspacesMutation,
  useUpdateWorkspaceMutation,
  useGetFlatfileAccessTokenQuery,
  useGetWorkspaceSubmodulesQuery,
  useUploadFullReportFileMutation,
  useLazyDownloadFullReportFileQuery,
  useGetIsFullReportFileUploadedQuery,
  useResendWorkspaceInviteMutation,
} = workspacesExtendedApi;

export const {
  useGetWorkspaceDocumentsQuery,
  useUploadWorkspaceDocumentMutation,
  useGetWorkspaceDocumentsSASTokenQuery,
  useUpdateWorkspaceDocumentMutation,
  useDeleteWorkspaceDocumentMutation,
  useGetWorkspaceDocumentImagesQuery,
} = workspaceDocumentsExtendedApi;

export const {
  useGetSubmodulesByModuleQuery,
  useLazyGetSubmoduleBySubmoduleIdQuery,
  useInsertDataTableMutation,
  useUpdateDataTableMutation,
  useGetDataTableQuery,
  useLazyGetDataTableQuery,
  useCreateCommentThreadMutation,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useAddCommentToThreadMutation,
  useUpdateCommentThreadMutation,
  useLazyGetIssuesQuery,
  useTriggerValidationMutation,
  useAssignDataCollectorMutation,
  useGetUserSubmodulesQuery,
} = submodulesExtendedApi;

export const {
  useGetThemesQuery,
  useLazyGetThemesQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  useUploadThemeResourcesMutation,
  useDeleteThemeMutation,
  useGetBlobTokenQuery,
  usePublishThemeMutation,
} = keyThemesEndpoints;

export const { useLazyDownloadFullPptQuery } = modulesEndpoints;

export const { useUploadExcelTemplateMutation } = extendedExcelApi;
